import React from 'react'
import { Link } from 'gatsby'
import { Box, Button, Divider, Heading, IconButton, Image } from '@chakra-ui/core'
import About from './About'
import BrandList from './BrandList'
import StackList from './StackList'
import FooterStyle from './styles/Footer'
import { noCookie, iconCircle } from '../assets/img'

export default ({ goTop }) => {
  return (
    <FooterStyle id='footer'>
      { goTop && (
        <IconButton className='action up' variantColor="teal" aria-label='go to top'
          icon='arrow-up'
          onClick={goTop}
        />
      )}
      <Box>
        {/* Company Info */}
        <Image src={iconCircle} style={{margin:'0 auto'}}/>
        <About />
        <StackList />

        {/* Trusted Brands */}
        <Divider />
        <Box>
          <Heading as='h3' size='lg'>
            Trusted Brands:
          </Heading>
        </Box>
        <BrandList />
        <Box>
          <Link to='/demos'>
            <Button className='action' variantColor="teal" size="lg">
              Demos
            </Button>
          </Link>
        </Box>
        <Divider />
        <Box>
          <h5 className='responsive'>
            <Image src={noCookie} />
            No Cookies For You on this Site!
          </h5>
        </Box>
        <p style={{fontSize:'.9em'}}>
          <span style={{color:'red'}}>❤️</span> This site is made with Gatsby/Chakra Love
        </p>
      </Box>
    </FooterStyle>
  );
}
