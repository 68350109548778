import React from 'react'
import { Link, navigate } from 'gatsby'
import {
  Button, Flex, IconButton, Tooltip, useDisclosure,
  Menu, MenuButton, MenuList, MenuItem, MenuGroup, MenuDivider,
} from '@chakra-ui/core'
import ContactModal from './ContactModal'

const tr = {
  contact : 'Contact Me',
  menu    : 'Menu',
  hMem    : 'Members',
  hWhat   : 'Value Statement',
  hStack  : 'My Stack',
  hDemo   : 'Case Studies',
  hAbout  : 'About Us',
}

export default ({
  colorMode, toggleColorMode, parallax, pageName='home'
}) => {

  const { isOpen, onOpen, onClose } = useDisclosure()

  const navigateOrScroll = index => {
    if (pageName==='home') {
      parallax(index)
    } else if (index===3) {
      navigate(`/${pageName}#footer`)
    } else {
      navigate(`/#${index}`)
    }
  }

  return (
    <>
      <Flex p={4} justify='space-between' flexDir='row' width='100%' style={{
        position:'fixed',
        top:'0',
        zIndex:'999',
        backgroundColor: colorMode==='light'?'rgba(255,255,255,.5)':'rgba(26, 32, 44,.5)',
      }}>
        <Link to='/'>
          <span className='satisfy'>KWA</span>
        </Link>
        <Tooltip label={colorMode==='light'?'Turn off the lights':'Make it day again'}>
          <IconButton onClick={toggleColorMode}
            variantColor={colorMode==='light'?'yellow':'teal'}
            aria-label='Call Segun'
            size='lg'
            isRound={true}
            variant='ghost'
            icon={colorMode==='light'?'sun':'moon'}
          />
        </Tooltip>
        <Tooltip label={tr.contact}>
          <IconButton onClick={(onOpen)}
            variantColor={colorMode==='light'?'yellow':'teal'}
            aria-label='Call Segun'
            size='lg'
            isRound={true}
            variant='ghost'
            icon='edit'
          />
        </Tooltip>
        <Menu>
          <Tooltip label={tr.menu}>
            <MenuButton as={Button}
              rightIcon='drag-handle'
              variantColor={colorMode==='light'?'yellow':'teal'}
              aria-label='Call Segun'
              size='lg'
              isRound={true}
              variant='ghost'
            />
          </Tooltip>
          <MenuList>
            <MenuGroup title="Option">
              <MenuItem onClick={toggleColorMode}>Toggle Color</MenuItem>
              <MenuItem onClick={onOpen}>{tr.contact}</MenuItem>
            </MenuGroup>
            <MenuDivider />
            <MenuGroup title="Navi">
              <Link to='/demos'>
                <MenuItem>{tr.hDemo}</MenuItem>
              </Link>
              <MenuItem onClick={() => navigateOrScroll(0)}>Top</MenuItem>
              <MenuItem onClick={() => navigateOrScroll(1)}>Stack</MenuItem>
              <MenuItem onClick={() => navigateOrScroll(2)}>Values</MenuItem>
              <MenuItem onClick={() => navigateOrScroll(3)}>About</MenuItem>
            </MenuGroup>
          </MenuList>
        </Menu>
      </Flex>
      <ContactModal isOpen={isOpen} onClose={onClose} />
    </>
  );
}
