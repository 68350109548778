import * as img from '../assets/img'
import * as anim from '../assets/anim'

export default [
  {
    header: {
      EN: 'ReachLocal Nimbus',
      JP: '意匠計画',
    },
    locale: {
      EN: 'Global',
      JP: '新宿',
    },
    industry: {
      EN: 'SEM Marketing',
      JP: '3DCG / VR',
    },
    short: {
      EN: 'Marketing Automatation, Search Engine Marketing Optimization',
      JP: 'プロジェクト管理システム、3Dモデリング、プラグイン開発',
    },
    desc: {
      EN: 'Marketing Automatation, Search Engine Marketing Optimization',
      JP: 'プロジェクト管理システム、3Dモデリング、プラグイン開発',
    },
    // logo: img.logoIkeikaku,
    image: anim.demoNimbus,
    link: 'https://www.i-keikaku.co.jp/',
  },
  {
    header: {
      EN: 'Common Thread Collective',
      JP: 'Common Thread Collective',
    },
    locale: {
      EN: 'Orange County',
      JP: 'Orange County',
    },
    industry: {
      EN: 'Online Marketing',
      JP: 'Online Marketing',
    },
    short: {
      EN: 'Shopify, Adwords, Facebook Reporting Portal',
      JP: 'Shopify, Adwords, Facebook レポートポータル',
    },
    desc: {
      EN: 'Shopify, Adwords, Facebook Reporting Portal',
      JP: 'Shopify, Adwords, Facebook レポートポータル',
    },
    logo: img.logoCtc,
    image: anim.demoCtc,
    link: '',
  },
  {
    header: {
      EN: 'BeFoundOnline',
      JP: 'BeFoundOnline',
    },
    locale: {
      EN: 'Chicago',
      JP: 'Chicago',
    },
    industry: {
      EN: 'Online Marketing',
      JP: 'Online Marketing',
    },
    short: {
      EN: 'Data Studio: GoogleMyBusiness, Adwords, Facebook API Development',
      JP: 'Data Studio: GoogleMyBusiness, Adwords, Facebook API開発',
    },
    desc: {
      EN: 'Data Studio: GoogleMyBusiness, Adwords, Facebook API Development',
      JP: 'Data Studio: GoogleMyBusiness, Adwords, Facebook API開発',
    },
    logo: img.logoBefound,
    image: anim.demoBefound,
    link: 'https://befoundonline.com/',
  },
  {
    header: {
      EN: 'OutdateRx',
      JP: 'OutdateRx',
    },
    locale: {
      EN: 'Alabama',
      JP: 'Alabama',
    },
    industry: {
      EN: 'Pharmaceutical',
      JP: 'Pharmaceutical',
    },
    short: {
      EN: 'Cloud Migration & Customer Reporting Portal',
      JP: 'クラウド移行と顧客レポートポータル',
    },
    desc: {
      EN: 'Cloud Migration & Customer Reporting Portal',
      JP: 'クラウド移行と顧客レポートポータル',
    },
    logo: img.logoOutdaterx,
    image: anim.demoOutdate,
    link: 'http://outdaterx.com',
  },
  // {
  //   header: {
  //     EN: 'Kytabu',
  //     JP: 'Kytabu',
  //   },
  //   locale: {
  //     EN: 'Nairobi, Kenya',
  //     JP: 'Nairobi, Kenya',
  //   },
  //   industry: {
  //     EN: 'Education',
  //     JP: 'Education',
  //   },
  //   short: {
  //     EN: 'Lorem ipsum dolor sit amet.',
  //     JP: '号イ構紙トチ所共銃めつわ供検たぴイ',
  //   },
  //   desc: {
  //     EN: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  //     JP: '号イ構紙トチ所共銃めつわ供検たぴイ済4明はほやイ事連げ転関ーを手宝んぐゆて野保めなクち保極材く避1高なドぎッ手家しんら。1属アオレロ面九カマ真母鮮ざぴぼで鋭唆姿アキレマ相続キセ電写オクユ止国必9要ッち新動ぼぐざん作囲せ密形ルん許条学ホヒハネ県見読年松官ほべゆぎ。数ヨカ人邸ながゅ医民たうくイ事7要けえれ米氏ふびべむ水砂ヒ井泉モ応零ムカヨ際高シマヤ請開エノ細両トホ成声起也らの。',
  //   },
  //   logo: logoTrenzyton,
  //   image: demoIkeikaku,
  //   link: '',
  // },
  {
    header: {
      EN: 'LineCrunch',
      JP: 'LineCrunch',
    },
    locale: {
      EN: 'San Francisco',
      JP: 'San Francisco',
    },
    industry: {
      EN: 'Sports',
      JP: 'Sports',
    },
    short: {
      EN: 'High-powered sports dashboard, data scraping, API development',
      JP: '強力なスポーツダッシュボード、データスクレイピング、API開発',
    },
    desc: {
      EN: 'High-powered sports dashboard, data scraping, API development',
      JP: '強力なスポーツダッシュボード、データスクレイピング、API開発',
    },
    logo: img.logoLc,
    image: anim.demoLineCrunch,
    link: 'https://linecrunch.com/dashboard',
  },
  {
    header: {
      EN: 'Business CPR',
      JP: 'Business CPR',
    },
    locale: {
      EN: 'SLC, Utah',
      JP: 'SLC, Utah',
    },
    industry: {
      EN: 'Business Consulting, Accounting and Finance',
      JP: 'Business Consulting, Accounting and Finance',
    },
    short: {
      EN: 'Developed Quickbooks / Excel integrated financial analysis tool',
      JP: '強力なスポーツダッシュボード、データスクレイピング、API開発',
    },
    desc: {
      EN: 'Developed Quickbooks / Excel integrated financial analysis tool',
      JP: '強力なスポーツダッシュボード、データスクレイピング、API開発',
    },
    // logo: img.logoLc,
    image: anim.demoBcpr,
    link: 'https://linecrunch.com/dashboard',
  },
  {
    header: {
      EN: 'FreshBowls',
      JP: 'FreshBowls',
    },
    locale: {
      EN: 'New York',
      JP: 'New York',
    },
    industry: {
      EN: 'Food & Beverage',
      JP: 'Food & Beverage',
    },
    short: {
      EN: 'Desktop Application, 3rd Party Credit Card Processing',
      JP: 'デスクトップアプリケーション、サードパーティのクレジットカード処理',
    },
    desc: {
      EN: 'Desktop Application, 3rd Party Credit Card Processing',
      JP: 'デスクトップアプリケーション、サードパーティのクレジットカード処理',
    },
    logo: img.logoFreshbowl,
    image: img.demoFreshbowl,
    link: '',
  },
  {
    header: {
      EN: 'Handy & JRE',
      JP: 'Handy & JRE',
    },
    locale: {
      EN: 'Tokyo',
      JP: 'Tokyo',
    },
    industry: {
      EN: 'Travel',
      JP: 'Travel',
    },
    short: {
      EN: 'Landing Page Design & Optimization',
      JP: 'ランディングページのデザインと最適化',
    },
    desc: {
      EN: 'Landing Page Design & Optimization',
      JP: 'ランディングページのデザインと最適化',
    },
    logo: img.logoHandy,
    image: img.demoHandy,
    link: 'https://handy-japan.com/cp/jreast_handy/index.en.html',
  },
  // {
  //   header: {
  //     EN: 'ViaTechnik',
  //     JP: 'ViaTechnik',
  //   },
  //   locale: {
  //     EN: 'Chicago',
  //     JP: 'Chicago',
  //   },
  //   industry: {
  //     EN: 'Architecture',
  //     JP: 'Architecture',
  //   },
  //   short: {
  //     EN: 'Lorem ipsum dolor sit amet.',
  //     JP: '号イ構紙トチ所共銃めつわ供検たぴイ',
  //   },
  //   desc: {
  //     EN: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  //     JP: '号イ構紙トチ所共銃めつわ供検たぴイ済4明はほやイ事連げ転関ーを手宝んぐゆて野保めなクち保極材く避1高なドぎッ手家しんら。1属アオレロ面九カマ真母鮮ざぴぼで鋭唆姿アキレマ相続キセ電写オクユ止国必9要ッち新動ぼぐざん作囲せ密形ルん許条学ホヒハネ県見読年松官ほべゆぎ。数ヨカ人邸ながゅ医民たうくイ事7要けえれ米氏ふびべむ水砂ヒ井泉モ応零ムカヨ際高シマヤ請開エノ細両トホ成声起也らの。',
  //   },
  //   logo: logoTrenzyton,
  //   image: demoIkeikaku,
  //   link: '',
  // },
]