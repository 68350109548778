import React, { useState, useRef } from 'react'
import { Flex, Text } from '@chakra-ui/core'
import { animated, useSpring, useTransition, useChain, config } from 'react-spring'
import PandoraStyles from './styles/Pandora'
import { AniGrid } from '../animations'
import { iconCircle } from '../assets/img'
import services from '../const/services'

/**
 * goNext: {function} moves to next page
 */
export default ({ goNext }) => {
  const [open, setOpen] = useState(false)
  const springRef = useRef()
  const transRef = useRef()

  const { size, opacity, ...rest } = useSpring({
    ref: springRef,
    config: config.stiff,
    from: { size: '50%' },
    to: {
      size: open ? '100%' : '50%',
    }
  })
  const transitions = useTransition(open ? services : [], item => item.en, {
    ref: transRef,
    unique: true,
    trail: 400 / services.length,
    from: { opacity: 0, transform: 'scale(0)' },
    enter: { opacity: 1, transform: 'scale(1)' },
    leave: { opacity: 0, transform: 'scale(0)' }
  })

  useChain(open ? [springRef, transRef] : [transRef, springRef], [0, open ? 0.1 : 0.6])

  // renderers

  const startBubble = () => (
    <Flex className='action start-bubble'>
      <Text textAlign='center' verticalAlign='middle' width='50%' style={{fontSize:'min(max(10px, 1.5vw),16px)',maxWidth:'400px'}}>
        Web/Desktop/Cloud Software Development
      </Text>
    </Flex>
  )

  const middleSquare = () => (
    <Flex justify='center' align='center' flexDir='column'>
      <img
        width='50%'
        src={iconCircle}
        alt='full-stack'
      />
      <p>Full-Stack Development</p>
    </Flex>
  )

  const boxItem = (item) => (
    <span classname='responsive-font-size'>
      {item.en}
    </span>
  )

  const box = ({ item, key, props }) => (
    <animated.div key={key}
      onClick={goNext}
      style={{ ...props, width: '90%', maxWidth: '300px', height: 'calc(100vw / 4)', maxHeight: '300px', background: item.css, margin: 'auto', borderRadius: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}
    >
      { item.en === 'Kwa'
        ? middleSquare()
        : boxItem(item)
      }
    </animated.div>
  )

  return (
    <PandoraStyles>
      <AniGrid
        rest={rest}
        size={size}
        setOpen={setOpen}
      >
        {
          !open
          ? startBubble() 
          : transitions.map(box)
        }
      </AniGrid>
    </PandoraStyles>
  )
}
