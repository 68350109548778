import styled from 'styled-components'

export default styled.footer`
  /* footer body */
  color: #4f4f4f;
  width: 95%;
  max-width: 1200px;
  text-align: center;
  height: 100vh;
  max-height: 1000px;
  margin-top: 25vh;
  background-color: #F8F8F9;
  border-radius: 20px;
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 2rem;
  a {
    color: rgb(134, 179, 172);
  }
  hr {
    margin: 1.25rem auto;
  }
  .up {
    border: none;
    position: absolute;
    right: 5%;
  }
  h5 {
    img {
      width: 25px;
      height: 25px;
      display: inline-block;
      margin-top: -5px;
    }
  }
`