import { illOpen, illFlexible, illTrust } from '../assets/img'

export default [
  {
    header: {
      EN: 'Open',
      JP: 'Open',
    },
    desc: {
      EN: `
The current state of software development is dynamic fast-paced world where new ideas get brought to the forefront every day.
We need to be open to new ideas and ways of doing things, or become obsolete.
Software is no longer hidden behind the scenes, and we developers need to be flexible to able to adapt to different situations, people, and ideas.
`,
      JP: `
現代におけるソフトウェア開発は新しいアイディアにより、日々進化し続けている分野です。
そのため、新しいアイディアややり方を柔軟に取り入れていかなければ、すぐに時代遅れとなってしまいます。
ソフトウェアはスクリーンの裏に隠れているものではもはやありません。
私たち開発者は様々な状況や顧客のニーズ、また新しいアイディアに柔軟に対応していかなければなりません。
`,
    },
    image: illOpen,
  },
  {
    header: {
      EN: 'Focused',
      JP: 'Focused',
    },
    desc: {
      EN: `
A one month long software project easily becomes a 1-year long project as business conditions change.
An elongated software project is a huge hurdle for established companies, and usually means the end of a startup.
This is why we must keep focused in order to accomplish what needs to be done, as our clients really depend on us.
`,
      JP: `
1ヶ月の予定であったソフトウェア開発は状況により簡単に１年がかりのプロジェクトになりえます。
ソフトウェア開発の遅延は新規企業にとって大きな負担となり、結果としてスタートアップの終焉をもたらします。
このため、顧客は我々を必要とし、また我々は顧客のニーズを達成するため集中して取り組む必要があります。
`,
    },
    image: illFlexible,
  },
  {
    header: {
      EN: 'Humble',
      JP: 'Humble',
    },
    desc: {
      EN: `
Developers, designers, business managers are just a part of a larger society that keeps the world moving.
We should respect and help each other. We should be patient when someone doesn't understand something or wants to share their idea.
We should ask questions when we don't know. And we should to make our world a better place.
`,
      JP: `
開発者、デザイナー、ビジネスマネージャーは世界を動かしている大きな社会の一部分にすぎません。
私たちはお互いに尊重し助け合うべきです。私たちは他者が理解に時間がかかっている時や彼らの考えを共有しようとしている時には忍耐強くなければなりません。
わからないときは質問しましょう。そして私たちの世界をより良い場所にしましょう。
`,
    },
    image: illTrust,
  },
]