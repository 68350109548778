import styled from 'styled-components'
import { thoughtCloud } from '../../assets/img'

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 100px);
  min-height: 300px;
  min-width: 300px;
  max-height: 900px;
  max-width: 900px;

  .start-bubble {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: black;
    grid-column-start: 1;
    grid-column-end: 4;
    background: url(${thoughtCloud});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    font-weight: 700;
    @media (max-width: 800px) {
      width: 100vw;
      height: 100vw;
      margin-left: -50%;
    }
  }
`