import React from 'react'
import { Box, Heading, Image, Text } from '@chakra-ui/core'

export default ({ lang, item, index, goNext }) => {
  const bodyStyles = {
    textAlign: 'center',
    display: 'flex', flexDirection:'row', justifyContent: 'center', alignContent: 'center', alignItems: 'center',
    marginTop:'-2rem',
  }

  const heading = (item) => (
    <Heading size='md' style={{display:'inline-flex'}}>
      <Box style={{marginRight:'.5rem'}}>
        { item.header[lang] }
        <br />
        { item.locale[lang] }
        <br />
        { item.industry[lang] }
      </Box>
      <Image
        height='80px'
        src={ item.logo }
        alt={ item.header['EN'] }
      />
    </Heading>
  )

  const img = (item) => (
    <Image
      height='30vw'
      src={ item.image }
      alt={ item.short['EN'] }
    />
  )

  const txt = (item) => (
    <Box style={{padding:'1rem'}}>
      <Text>
        { item.desc[lang] }
      </Text>
      {/* <Button onClick={goNext}>Next</Button> */}
    </Box>
  )

  return (
    <>
      {
        index % 2 === 0 ?
        <Box id={item.header.EN} style={{marginTop: '2rem', padding: '10% 1rem', textAlign: 'left'}}>
          { heading(item) }
          <Box style={bodyStyles}>
            { txt(item) }
            { img(item) }
          </Box>
        </Box>
        :
        <Box id={item.header.EN} style={{marginTop: '2rem', padding: '10% 1rem', textAlign: 'right'}}>
          { heading(item) }
          <Box style={bodyStyles}>
            { img(item) }
            { txt(item) }
          </Box>
        </Box>
      }
    </>
  )
}