const url = 'https://us-east1-callmekwa.cloudfunctions.net/test-email-msg'

const fetchData = async(url, options) => {
  try {
    return await fetch(url, options)
  } catch (error) {
    return error
  }
}

export default async (subject, message) => {

  const data = { subject, message }
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    json: true,
  }

  return await fetchData(url, options)
}