import React from 'react'
import { animated } from 'react-spring'

const gridStyle = {
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: 'repeat(3, minmax(30%, 1fr))',
  gridGap: '5px',
  gridRowGap: '25px',
  padding: '5px',
  cursor: 'pointer',
  willChange: 'width, height',
  zIndex: '100',

}

export default ({children, rest, size, setOpen}) => {
  return (
    <animated.div
      style={{ ...rest, width: size, height: size, ...gridStyle }}
      className='responsive-font-size'
      onClick={() =>  setOpen(prev => !prev)}
    >
      {children}
    </animated.div>
  )
}