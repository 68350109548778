const colors = {
  '1a': '#BEDEF7',
  '1b': '#82c3f5',
  '2a': '#CACFF5',
  '2b': '#8490f0',
  '3a': '#D4C3F7',
  '3b': '#ac89f5',
  '4a': '#E5ACF3',
  '4b': '#d969f5',
  '5a': '#FB90F0',
  '5b': '#f551e4',
}

export default [
  {
    en: 'User A/B Testing & UX Feedback',
    css: `linear-gradient(135deg, ${colors['2a']} 0%, ${colors['2b']} 100%)`,
  },
  {
    en: 'Lang: NodeJS / Python / C#',
    css: `linear-gradient(135deg, ${colors['1a']} 0%, ${colors['1b']} 100%)`,
  },
  {
    en: 'Cloud: AWS / Google Cloud / Azure',
    css: `linear-gradient(135deg, ${colors['2a']} 0%, ${colors['2b']} 100%)`,
  },
  {
    en: 'etc: web Security, unit-testing, stress-testing',
    css: `linear-gradient(135deg, ${colors['3a']} 0%, ${colors['3b']} 100%)`,
  },
  {
    en: 'Kwa',
    css: 'rgba(255,255,255,0)',
  },
  {
    en: 'Db: MySQL / Postgres / MongoDb / Cassandra',
    css: `linear-gradient(135deg, ${colors['3a']} 0%, ${colors['3b']} 100%)`,
  },
  {
    en: 'Backend: Lambda, RabbitMQ, Cronjobs, Kafka',
    css: `linear-gradient(135deg, ${colors['4a']} 0%, ${colors['4b']} 100%)`,
  },
  {
    en: 'UI: React / Angular',
    css: `linear-gradient(135deg, ${colors['5a']} 0%, ${colors['5b']} 100%)`,
  },
  {
    en: 'APIS: Rest / GraphQL',
    css: `linear-gradient(135deg, ${colors['4a']} 0%, ${colors['4b']} 100%)`,
  },
]