import React from 'react'
import { useSpring, animated } from 'react-spring'

const interp = i => r => `translate3d(${15 * Math.sin(r + (i * 2 * Math.PI) / 1.6)}px, 0, 0)`

/** This script seems to not be performant... */
export default ({ children }) => {
  
  const { radians } = useSpring({
    to: async next => {
      while (1) await next({ radians: 2 * Math.PI })
    },
    from: { radians: 0 },
    config: { duration: 3500 },
    reset: true,
  })

  return (
    <animated.div style={{ transform: radians.interpolate(interp(1)) }} >
      { children }
    </animated.div>
  )
}