import React, { useEffect, useState } from 'react'
import { Flex, Icon } from '@chakra-ui/core'
import { animated } from 'react-spring'


export default ({ props }) => {

  const [r, setOffset] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setOffset(prev => prev > 1000 ? -1000 : prev + 1)
    }, 100)

    return () => clearInterval(interval)
  }, [])

  const trans1 = (x, y) => `translate3d(${x / 9 + r + 1  }px,${y / 9 + 100}px,0)`
  const trans2 = (x, y) => `translate3d(${x / 8 + r + 35 }px,${y / 8 + 430}px,0)`
  const trans3 = (x, y) => `translate3d(${x / 6 + r + 250}px,${y / 6 + 600}px,0)`
  // const trans4 = (x, y) => `translate3d(${x / 3 + r + 400}px,${y / 3 + 799}px,0)`
  
  const trans1a = (x, y) => `translate3d(${x / 9 - r - 1  }px,${y / 9 + 150}px,0)`
  const trans2a = (x, y) => `translate3d(${x / 8 - r - 35 }px,${y / 8 + 466}px,0)`
  const trans3a = (x, y) => `translate3d(${x / 6 - r - 650}px,${y / 6 + 755}px,0)`
  // const trans4a = (x, y) => `translate3d(${x / 3 - r - 400}px,${y / 3 + 950}px,0)`

  return (
    <>
      <Flex p={4} 
        width='100%'
        height='100%'
        style={{position:'absolute', top:'0', left: '0', zIndex:'-1', overflow: 'hidden'}}
      >
        <animated.div
          style={{ transform: props.xy.interpolate(trans1) }}
        >
          <span style={{color:'rgba(85, 85, 85, .5)',left:'85px',top:'10px',position:'relative'}}>Javascript</span>
          <Icon name='cloud' color='translucent' size='96px' />
        </animated.div>
        <animated.div
          style={{ transform: props.xy.interpolate(trans2) }}
        >
          <Icon name='cloud' color='translucent' size='126px' />
        </animated.div>
        <animated.div
          style={{ transform: props.xy.interpolate(trans3) }}
        >
          <Icon name='cloud' color='translucent' size='48px' />
        </animated.div>
        {/* <animated.div
          style={{ transform: props.xy.interpolate(trans4) }}
        >
          <Icon name='cloud' color='translucent' size='88px' />
        </animated.div> */}
      </Flex>
      <Flex p={4} 
        width='100%'
        height='100%'
        justify='flex-end'
        style={{position:'absolute', top:'0', right: '0', zIndex:'-1'}}
      >
        <animated.div
          style={{ transform: props.xy.interpolate(trans1a) }}
        >
          <Icon name='cloud' color='translucent' size='92px' />
        </animated.div>
        <animated.div
          style={{ transform: props.xy.interpolate(trans2a) }}
        >
          <span style={{color:'rgba(85, 85, 85, .5)',left:'75px',top:'10px',position:'relative'}}>Node</span>
          <Icon name='cloud' color='translucent' size='115px' />
        </animated.div>
        <animated.div
          style={{ transform: props.xy.interpolate(trans3a) }}
        >
          <Icon name='cloud' color='translucent' size='36px' />
        </animated.div>
        {/* <animated.div
          style={{ transform: props.xy.interpolate(trans4a) }}
        >
          <Icon name='cloud' color='translucent' size='146px' />
        </animated.div> */}
      </Flex>
    </>
  );
}
