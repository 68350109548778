import React from 'react'
import styled from 'styled-components'
import { Image, Tooltip } from '@chakra-ui/core'
import demos from '../const/demos'

const Style = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  overflow-x: hidden;
  img {
    text-align: center;
    margin: .25rem;
    height: 50px;
    @media (max-width: 1000px)  { height: 40px; }
    @media (max-width: 800px)  { height: 30px; }
  }
`

export default () => {
  return (
    <Style>
      {demos.map((item, i) => (
        <Tooltip label={item.short.EN} key={item.header.EN}>
          <Image src={item.logo} className='action' />
        </Tooltip>
      ))}
    </Style>
  )
}