import React from 'react'
import { Box, Heading } from '@chakra-ui/core'
import Copyright from './Copyright'

const aboutInfo = [
  'Full Stack Developer',
  'Walnut, CA 91789',
]

export default () => {
  return (
    <>
      <Box>
        <Heading as='h3' size='lg' className='satisfy'>
          About Me
        </Heading>
      </Box>
      {aboutInfo.map((nfo, i) => <p key={'nfo'+i}>{ nfo }</p>)}
      <p><a href="/demos">See my Demos</a></p>
      <Copyright />
    </>
  )
}
