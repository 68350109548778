import React, { useState } from 'react'
import {
  Button, Input, Text, Textarea,
  Modal, ModalOverlay, ModalContent,
  ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
} from '@chakra-ui/core'
import submitContact from '../fn/submitContact'

const tr = {
  title : {
    en: 'Send Us a Message',
    jp: 'メッセージを送る',
  },
  body : {
    en: 'Let us know if we can help you with anything or if you\'re interested in joining our team! Responses generally come within 24 hours.',
    jp: '何かお手伝いできることはありますか。私たちのチームにご興味のある方は是非ご連絡をください。24時間以内に必ず返信します。',
  },
  placeholder: {
    en: 'Message goes here!',
    jp: 'メッセージをください',
  },
  namePh: {
    en: 'Name please',
    jp: 'お名前をご記入ください',
  },
  emlPh: {
    en: 'Email please',
    jp: 'Emailアドレスをご記入ください',
  },
  send: {
    en: 'Send',
    jp: '迷信',
  },
  noMsgErr: {
    en: 'Oops! You forgot to enter your message!',
    jp: 'メッセージが未入力です',
  },
  noFrErr: {
    en: 'Oops! You forgot to enter your name!',
    jp: 'お名前が未入力です',
  },
  noEmlErr: {
    en: 'Oops! You forgot to enter your email!',
    jp: 'Emailアドレスが未入力です',
  },
  Err: {
    en: 'Uh oh! There was some error. Please email us directly at kwa@gmail.com',
    jp: 'エラーです。申し訳ありませんがkwa@gmail.comまで直接メールをお願いします。',
  },
}

export default ({ isOpen, onClose }) => {
  const [msg, setMsg] = useState('')
  const [fr, setFr] = useState('')
  const [eml, setEml] = useState('')
  const [loading, setLoading] = useState(false)

  const getTranslation = o => o.en

  const handleMsgChange = e => setMsg(e.target.value)
  const handleFrChange = e => setFr(e.target.value)
  const handleEmlChange = e => setEml(e.target.value)

  const checkIncomplete = () => !msg || !fr || !eml

  const send = async() => {
    if (!msg) return alert(getTranslation(tr.noMsgErr))
    if (!fr) return alert(getTranslation(tr.noFrErr))
    if (!eml) return alert(getTranslation(tr.noEmlErr))
    if (!eml || eml.toString().indexOf('@') === -1 || eml.toString().indexOf('.') === -1) {
      return alert(getTranslation(tr.noEmlErr))
    }
    setLoading(true)
    
    await submitContact(
      `Kwa.Dev Contact from ${fr}`,
      [ `<p>Email: ${eml}</p>`,
        `<p>Message: ${msg}</p>`,
      ].join(' '),
    )
    onClose()
    setMsg('')
    setFr('')
    setEml('')
    setLoading(false)
  }

  return (
    <Modal blockScrollOnMount={true} isOpen={isOpen} onClose={onClose} isCentered={true} >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          { getTranslation(tr.title) }
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={4} >
            { getTranslation(tr.body) }
          </Text>
          <Input type="text" id="name" aria-describedby="name-helper-text" mb={2}
            placeholder={ getTranslation(tr.namePh) }
            onChange={handleFrChange}
            value={fr}
          />
          <Input type="email" id="email" aria-describedby="email-helper-text" mb={2}
            placeholder={ getTranslation(tr.emlPh) }
            onChange={handleEmlChange}
            value={eml}
          />
          <Textarea size='lg'
            placeholder={ getTranslation(tr.placeholder) }
            onChange={handleMsgChange}
            value={msg}
          />
        </ModalBody>
        <ModalFooter>
          <Button rightIcon={checkIncomplete() ? '' : 'arrow-forward'}
            variantColor='green'
            color='white'
            onClick={send}
            isLoading={loading}
          >
            { getTranslation(tr.send) }
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
