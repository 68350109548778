import React from 'react'
import styled from 'styled-components'
import { Image } from '@chakra-ui/core'

import {
  cpNodejs,
  cpElectron,
  cpRust,
  cpAws,
  cpAzure,
  cpGcloud,
  cpPython,
  cpServerless,
  cpReact,
  cpAngular,
} from '../assets/img'

const stack = [
  { name: 'Nodejs', logo: cpNodejs },
  { name: 'React', logo: cpReact },
  { name: 'Angular', logo: cpAngular },
  { name: 'Aws', logo: cpAws },
  { name: 'Azure', logo: cpAzure },
  { name: 'Gcloud', logo: cpGcloud },
  { name: 'Electron', logo: cpElectron },
  { name: 'Serverless', logo: cpServerless },
  { name: 'Python', logo: cpPython },
  { name: 'Rust', logo: cpRust },
]

const Style = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  overflow-x: hidden;
  img {
    text-align: center;
    margin: .25rem;
    height: 50px;
    @media (max-width: 1000px)  { height: 40px; }
    @media (max-width: 800px)  { height: 30px; }
  }
`

export default () => {
  return (
    <Style>
      {stack.map((item) => (
        <Image src={item.logo} key={item.name} alt={item.name} />
      ))}
    </Style>
  )
}