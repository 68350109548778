import React from 'react'
import { Box, Heading, Image, Text } from '@chakra-ui/core'
import wanted from '../const/wanted'

const items = wanted

export default () => {

  const renderItem = (item) => (
    <Box key={item.header.EN}
      style={{borderRadius: '50%', padding: '12%', border: '10px solid #bdffec', textAlign: 'center', width: '90%', display: 'flex', flexDirection:'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center', margin: '0 auto', marginTop: '-10%' }}
    >
      <Heading size='md' style={{ color:'#bdffec', fontSize:'4em' }}>
        { item.header.EN }
      </Heading>
      <Image
        size='300px'
        src={ item.image }
        alt={ item.header['EN'] }
      />
      <Text>
        { item.desc.EN }
      </Text>
    </Box>
  )

  return (
    <Box style={{width:'100%',margin:'0 auto',textAlign:'right',maxWidth: '1200px'}}>
      <Heading size='lg' style={{fontSize:'4em',marginBottom:'2em',color:'#bdffec'}}>
        My Values
      </Heading>
      { items.map(renderItem) }
    </Box>
  )
}